.upload body {
    font-family: 'Noto Sans KR', sans-serif;
    background-color: #f4f4f8;
    margin: 0;
    padding: 0;
}

.upload .container {
    margin-top: calc(var(--navbar-height) + 10px);
    /*Navbar의 높이*/
    margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px);
    /* tabbar의 높이 */
    padding: 15px;
    max-width: 80%;
    /* margin: 2rem auto; */
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.upload .clear-fix::after {
    content: "";
    display: table;
    clear: both;
}

/* 
.upload-btn {
    font-size: 1.0em;
    padding: 12px 14px;
    background-color: #007BFF;
    color: #FFFFFF;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s;
    float: right; 
    position: fixed;

}

.upload-btn:hover {
    background-color: #0056b3;
} */


.upload .bottom-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--tabbar-height);
    height: var(--text-input-height);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;

}

.upload .action-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    width: 50%;
    margin: 5px 10px;
}

.upload .upload-btn:hover {
    background-color: #0056b3;
}

.upload .custom-file-upload {
    background-color: #007bff;
    color: #fff;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    display: inline-block;
    margin-right: 10px; /* 오른쪽 여백 추가 */
}

.upload .custom-file-upload:hover {
    background-color: #0056b3;
}

.upload .preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Provides consistent gaps */
}

.upload .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.upload .remove-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 0, 0, 0.7);
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 1em;
    border-radius: 50%;
    /* Making it circular */
}

.upload .preview-image {
    max-width: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
    object-fit: cover;
    border-radius: 6px;
    transition: transform 0.3s;
}

.upload .preview-image:hover {
    transform: scale(1.1);
}


/* Additional styles for better UI */
.upload .form-control {
    border: 1px solid #e2e2e4;
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 12px;
}

.upload .korean-price-label {
    display: block;
    font-size: 0.9em;
    margin-bottom: 16px;
}

.upload .image-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.upload .image-count {
    color: #666;
}


.upload .title-label,
.upload .content-label,
.upload .price-label {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 16px;
    display: block;
    /* 타이틀을 블록 요소로 설정 */
    color: #333;
}

/* 각 항목의 간격 조절 */
.upload .form-section {
    margin-bottom: 32px;
}

.upload .gm-style {
    cursor: crosshair !important;
}

/*파일 업로드*/
.upload .progress-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    padding: 20px;
    border-radius: 10px;
  }
  
  .upload .circular-progress {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .upload .circle-bg,
  .upload .circle {
    fill: none;
    stroke-width: 6;
    cx: 50%;
    cy: 50%;
    r: 44; /* r=45에서 선의 두께 절반인 3을 빼서 42로 설정 */
  }
  
  .upload .circle-bg {
    stroke: #eee;
  }
  
  .upload .circle {
    stroke: #f88;  /* 변경된 색상 */
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .upload .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
  }

@media (max-width: 768px) {

    /* 태블릿용 스타일을 여기에 추가 */
    .upload .container {
        padding: 10px 20px;
        max-width: 95%;
        /* 패딩 조절 */
    }

    .upload .upload-btn {
        font-size: 1.4em;
        /* 버튼 크기 조절 */
    }

    /* 필요한 경우 다른 스타일도 추가 */
}
/* 태블릿 (768px ~ 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {

    /* 태블릿용 스타일을 여기에 추가 */
    .upload .container {
        padding: 20px 40px;
        max-width: 90%;
        /* 패딩 조절 */
    }

    .upload .upload-btn {
        font-size: 1.4em;
        /* 버튼 크기 조절 */
    }

    /* 필요한 경우 다른 스타일도 추가 */
}

/* 데스크톱 (1024px 이상) */
@media (min-width: 1024px) {

    /* 데스크톱용 스타일을 여기에 추가 */
    .upload .container {
        padding: 40px 60px;
        max-width: 80%;
        /* 패딩 조절 */
    }

    .upload .upload-btn {
        font-size: 1.6em;
        /* 버튼 크기 조절 */
    }

    /* 필요한 경우 다른 스타일도 추가 */
}