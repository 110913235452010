.keyword-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .keyword-input-container form {
    display: flex;
    margin-bottom: 10px;
  }
  
  .keyword-input-container input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .keyword-input-container button {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .keyword-input-container button:hover {
    background-color: #45a049;
  }
  
  .keywords-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .keyword-item {
    background-color: #f5f5f5;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  
  .keyword-item span {
    color: red;
    margin-left: 5px;
  }
  
  .keywords-count {
    margin-top: 10px;
  }
  