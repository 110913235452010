.image-previews {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3); /* adding a semi-transparent background */
}

.image-preview {
  position: relative;
  width: 80px;
  height: 60px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}