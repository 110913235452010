.edit .container {
    margin-top: calc(var(--navbar-height) + 10px);
    /*Navbar의 높이*/
    margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px);
    /* tabbar의 높이 */
    padding: 15px;
    max-width: 80%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.edit body {
    font-family: 'Noto Sans KR', sans-serif;
    background-color: #f4f4f8;
    margin: 0;
    padding: 0;
}

.edit .clear-fix::after {
    content: "";
    display: table;
    clear: both;
}

.edit .bottom-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--tabbar-height);
    height: var(--text-input-height);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;  /* 다른 요소 위에 표시되도록 함 */
}

.edit .action-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; /* 오른쪽 정렬 */
    gap: 8px;
    cursor: pointer;
    width: 50%;
    margin: 5px 15px;
} 
.edit .upload-btn:hover {
    background-color: #0056b3;
}

/* .preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
} */
.edit .preview-container, .edit .map-container {
    flex: 0 1 calc(20% - 20px);
    margin-bottom: 20px;
    flex-direction: row;
    display: flex;
    padding: 15px;
    transition: box-shadow 0.3s, transform 0.3s;
    cursor: pointer;
    border: 1px solid #ccc; /* This will add a grey border */
}

  
.edit .preview-container:hover, .edit .map-container:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);  /* 볼록한 그림자 효과 */
    transform: scale(1.00);  /* 약간 확대 */
    border-radius: 8px;
  }
  
.edit .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.edit .remove-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 0, 0, 0.7);
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 4px 8px;
    font-size: 1em;
    border-radius: 50%; /* Making it circular */
}

.edit .preview-image {
    max-width: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
    object-fit: cover;
    border-radius: 6px;
    transition: transform 0.3s;
}

.edit .preview-image:hover {
    transform: scale(1.1);
}


/* Additional styles for better UI */
.edit .form-control {
    border: 1px solid #e2e2e4;
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 12px;
}

.edit .korean-price-label {
    display: block;
    font-size: 0.9em;
    margin-bottom: 16px;
}

.edit .image-input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit .image-count {
    color: #666;
}


.edit .title-label, 
.edit .content-label, 
.edit .price-label {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 16px;
    display: block; /* 타이틀을 블록 요소로 설정 */
    color: #333;
}

/* 각 항목의 간격 조절 */
.edit .form-section {
    margin-bottom: 32px;
}

/* 태블릿 (768px ~ 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
    /* 태블릿용 스타일을 여기에 추가 */
    .edit .container {
        padding: 20px 40px; /* 패딩 조절 */
    }

    .edit .upload-btn {
        font-size: 1.4em; /* 버튼 크기 조절 */
    }

    /* 필요한 경우 다른 스타일도 추가 */
}

/* 데스크톱 (1024px 이상) */
@media (min-width: 1024px) {
    /* 데스크톱용 스타일을 여기에 추가 */
    .edit .container {
        padding: 30px 60px; /* 패딩 조절 */
    }

    .edit .upload-btn {
        font-size: 1.6em; /* 버튼 크기 조절 */
    }

    /* 필요한 경우 다른 스타일도 추가 */
}

