/* .categoryBox {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    background-color: #f9f9f9;
}

.categoryButton {
    display: block;
    padding: 8px;
    margin: 5px 0;
    background-color: #e7e7e7;
    border: none;
    border-radius: 4px;
    text-align: left;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: medium;
}

.categoryButton:hover, .categoryButton.selectedCategory {
background-color: #4CAF50; 
}


.selectedCategory {
    font-weight: bold;
    background-color: #4CAF50;
    color: white;
}

.categoryContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.siblingCategoryList {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .hoveredCategoryList {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden; 
  }
  
   */

   /* CSS */
.categoryContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #ffffff !important;
}
.categoryBox {
    background-color: #ffffff !important;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-right: 1px solid #eee; /* 마지막 요소를 제외한 오른쪽 경계선 */
  }
  
  .categoryButton {
    padding: 8px 12px;
    margin: 4px 0;
    background-color: #f0f0f0;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .categoryButton:hover, .categoryButton.selectedCategory {
    background-color: white;
    color: black;
  }
  
  .hoveredCategoryList {
    background-color: #ffffff !important;
    position: absolute;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    border-radius: 4px;
    padding: 10px;
    z-index: 10; /* Ensure it's on top of other elements */
}
  /* 반응형 디자인 */
  @media (max-width: 768px) {
    .categoryBox {
      flex-direction: row;
      overflow-x: auto;
      border-right: none;
      border-bottom: 1px solid #eee; /* 모바일에서는 아래쪽 경계선 */
    }
    .hoveredCategoryList {
      top: 100%; /* 모바일에서는 카테고리 박스 바로 아래에 표시 */
      left: 0;
    }
  }
  