
.detail {
  font-family: 'Arial', sans-serif; 
}

.detail .container {
    margin-top: calc(var(--navbar-height) + 10px);
    margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px);
    margin-left:auto;
    margin-right:auto; 
    padding: 15px;
    max-width: 90%; 
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 5px;
}

.detail .product-container {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 80%;
    height: 100vh-calc(var(--tabbar-height) + var(--text-input-height) + 10px);
}

.detail .product {
    flex: 0 1 calc(20% - 20px); 
    margin-bottom: 20px; 
    flex-direction: column;
    display: flex;
  
    transition: box-shadow 0.3s, transform 0.3s;  
    cursor: pointer;  
  }
  
  .detail  .product:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); 
    transform: scale(1.02);
    border-radius: 8px;
  }
  
  .detail .product-info {
    padding: 25px; 
    border-top: 2px solid #e0e0e0;
    border-bottom: 2px solid #e0e0e0;
    margin-bottom: 30px;
    width: 100%;
    display: block; 
}

.detail .detail-container {
  display: flex;
 
  border-radius: 8px;
  overflow: hidden;
}

.detail .left-column {
  flex: 2;
  padding: 20px;
  background-color: #f0f0f0;
}

.detail .right-column {
  flex: 1;
  padding: 20px;
}


.detail .author, .detail .title, .detail .date, .detail .price {
    margin-bottom: 15px;  
    font-size: 1.1em;  
    text-align: left;
}

.detail .views, .detail .chats {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-size: 20px;
    color: #333;
}

.detail .action-buttons {
    display: flex;
    justify-content: space-between;
}

.detail .action-button {
    flex: 1;
    margin: 0 15px; 
    padding: 15px;  
    font-size: 1.2em;  
    transition: background-color 0.3s, transform 0.3s;
    border-radius: 5px; 
    background-color: #f5f5f5; 
}

.detail .action-button:hover {
    background-color: #e0e0e0; 
    transform: translateY(-2px); 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}

.detail .flex-end {
    display: flex;
    justify-content: flex-end;
}
#map {
    z-index: 1000; 
}
.detail .bottom-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--tabbar-height);
    height: var(--text-input-height);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 10px 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;  
}

.detail .like-button {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px; 
    width: 50%;
    margin-right: 1%; 
}

.detail .chat-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; 
    gap: 8px;
    cursor: pointer;
    width: 50%;
    margin-right: 1%;  
}
.detail .action-button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end; 
    gap: 8px;
    cursor: pointer;
    width: 50%;
}
.detail .like-button, .detail .chat-button, .detail .action-button {
    display: flex;
    justify-content: center;
    text-align: center;
    
    line-height: 50px; 
   
}
.detail .float-button {
    position: fixed;
    bottom: calc(var(--tabbar-height) + 30px);
    right: 50px;        
    width: 140px;    
    height: 60px;   

    align-items: center;
    background-color: initial;
    background-image: linear-gradient(rgba(46, 58, 89, .84), rgba(57, 31, 91, .84) 50%);
    border-radius: 42px;
    border-width: 0;
    box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px,rgba(46, 58, 89, 0.4) 0 8px 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand,sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    margin: 0;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(57, 31, 91, 0.6) 1px 1px 4px,rgba(57, 31, 91, 0.32) 4px 4px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
  }
  
  .detail .float-button:hover {
    background-image: linear-gradient(#B384C9, #391F5B 50%);
  }
  

.detail .image-previews {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}

.detail .image-preview {
  position: relative;
  width: 80px;
  height: 60px;
  overflow: hidden;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.detail .image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.detail .image-preview-container.selected {
  border: 2px solid blue; 
}

  @media (min-width: 768px) {
    .float-button {
      font-size: 14px;
      padding: 18px 34px;
    }
  }

.detail .grid-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 16px; 
  }
  @media (max-width: 2100px) {
    .detail .grid-container {
        grid-template-columns: repeat(6, 1fr);
    }
  }
    

  @media (max-width: 1800px) {
    .detail .grid-container {
        grid-template-columns: repeat(5, 1fr);
    }
  }
  
 
  @media (max-width: 1500px) {
    .detail .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (max-width: 1200px) {
    .detail .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
  }
  

  @media (max-width: 800px) {
    .detail .grid-container {
        grid-template-columns: repeat(1, 1fr);
    }
  }
  
  

  @media (max-width: 800px) {
      .detail .container {
      margin-top: calc(var(--navbar-height) + 10px);
      margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px); 
      margin-left:auto;
      margin-right:auto;
      padding: 15px;
      max-width: 100%;
    }
     
  
     .detail .product {
      flex: 1 1 100%; 
    } 
  

  
  }
  
@media (max-width: 768px) {

    .detail .detail-container {
      max-width: 100%;  
      padding: 5px;
      flex-direction: column; 
    }
  
    .detail .left-column {
      flex: none; 
      width: 100%; 
    }
  
    .detail .right-column {
      flex: none; 
      width: 100%; 
      order: 1; 
    }
    .detail .product-info {
        padding: 15px;
    }

    .detail .author, .detail .title, .detail .date, .detail .price {
        margin-bottom: 10px; 
        font-size: 1em; 
    }

    .detail .action-buttons {
        flex-direction: column;
    }

    .detail .action-button {
        margin: 10px 0; 
        padding: 10px;  
        font-size: 1em;  
    }
} 

/* 
.detail {
  font-family: 'Arial', sans-serif;
}

.detail .container {
  margin-top: calc(var(--navbar-height) + 10px);
  margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px);
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
  max-width: 90%;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border-radius: 5px;
}

.detail .product-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 80%;
  height: calc(100vh - var(--tabbar-height) - var(--text-input-height) - 10px);
}

.detail .product {
  flex: 0 1 calc(20% - 20px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  transition: box-shadow 0.3s, transform 0.3s;
  cursor: pointer;
}

.detail .product:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  transform: scale(1.02);
  border-radius: 8px;
}

.detail .product-info {
  padding: 25px;
  border-top: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 30px;
  width: 100%;
}

.detail .detail-container {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}

.detail .left-column,
.detail .right-column {
  padding: 20px;
}

.detail .left-column {
  flex: 2;
  background-color: #f0f0f0;
}

.detail .right-column {
  flex: 1;
}

.detail .author,
.detail .title,
.detail .date,
.detail .price {
  margin-bottom: 15px;
  font-size: 1.1em;
  text-align: left;
}

.detail .views,
.detail .chats,
.detail .action-button {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  color: #333;
}

.detail .action-buttons {
  display: flex;
  justify-content: space-between;
}

.detail .bottom-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--tabbar-height);
  height: var(--text-input-height);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 10px 20px;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.detail .grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 16px;
}

@media (max-width: 1200px) {
  .detail .container {
    padding: 15px;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .detail .detail-container {
    flex-direction: column;
  }

  .detail .left-column,
  .detail .right-column {
    width: 100%;
    order: 1;
  }

  .detail .product-info {
    padding: 15px;
  }

  .detail .author,
  .detail .title,
  .detail .date,
  .detail .price {
    font-size: 1em;
  }

  .detail .action-buttons {
    flex-direction: column;
  }

  .detail .action-button {
    margin: 10px 0;
    font-size: 1em;
  }
}
 */
