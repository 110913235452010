
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif; /* You can change this to another font if preferred */
}

.container {
  margin-top: calc(var(--navbar-height) + 10px);/*Navbar의 높이*/
  margin-bottom: calc(var(--tabbar-height) + 10px);  /* tabbar의 높이 */
  margin-left:auto;
  margin-right:auto;
  padding: 15px;
  max-width: 80%;
  background-color: #f8f8f8;
  border-radius: 8px 8px 8px 8px;

  /* display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;  */
}
.sub-category-list {
  display: flex; /* Flexbox 레이아웃을 사용하여 항목들을 일렬로 배치 */
  flex-direction: row; /* 항목들을 세로로 나열 */
  padding: 10px; /* 내부 여백 추가 */
  margin: 5px 0; /* 상단과 하단에 마진 추가 */
  list-style: none; /* 기본 리스트 스타일 제거 */
  border: none; /* 경계선 추가 */
  border-radius: 5px; /* 경계선 모서리를 둥글게 처리 */
  background-color: #f9f9f9; /* 배경색 설정 */
  max-height: 200px; /* 최대 너비 설정 */
}

.sub-category-list button {
  padding: 8px 12px; /* 버튼 내부 여백 설정 */
  margin: 5px 0; /* 버튼 간 마진 추가 */
  border: 1px solid #ccc;; /* 버튼의 경계선 제거 */
  color: darkgray;
  background-color: #ffffff; /* 버튼 배경색 설정 */
  cursor: pointer; /* 마우스 커서를 포인터로 설정 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
  font-size: 24px;
}

.sub-category-list button:hover {
  background-color: #e9e9e9; /* 버튼에 마우스를 올렸을 때의 배경색 변경 */
}

.category-sidebar {
  padding: 20px; /* 내부 여백 */
  border-right: 1px solid #ccc; /* 오른쪽에 경계선 추가 */
  height: calc(100vh - 40px); /* 뷰포트 높이를 기준으로 사이드바 높이 설정 */
  overflow-y: auto; /* 내용이 넘칠 경우 세로 스크롤바 표시 */
  background-color: #f8f9fa; /* 배경색 설정 */
}

/* General styles */
.container, .list-container{
  margin: 10 auto;
  padding: 10px;
  width: 100%;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-grow-1 {
  display: flex;
  flex-direction: column;
  align-items: start; /* 왼쪽 정렬 */
  gap: 5px; /* 요소들 사이의 간격 */
}

.product {
  flex: 0 1 calc(20% - 20px); /* 25%를 사용하면 한 줄에 4개의 상품이 나옵니다. 이를 조절하여 원하는 수로 변경 가능 */
  margin-bottom: 20px; /* 각 상품 아래의 여백 */
  flex-direction: column;
  display: flex;

  transition: box-shadow 0.3s, transform 0.3s;  /* 애니메이션 효과 추가 */
  cursor: pointer;  /* 마우스 포인터가 셀 위로 올라올 때 손가락 모양으로 바뀝니다. */
}

.product:hover {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);  /* 볼록한 그림자 효과 */
  transform: scale(1.02);  /* 약간 확대 */
  border-radius: 8px;
}

.detail-pic {
  width: 100%;
  height: 300px;
  border-radius: 8px;
  margin-top: 20px;
}

.float-button-old {
  position: fixed;
  bottom: calc(var(--tabbar-height) + 30px);
  right: 50px;        /* 20 pixels from the right side */
  width: 60px;    /* Width of the button */
  height: 60px;   /* Height of the button */
  background-color: #3e77b0;
  color: #fff; 
  border-radius: 50%; /* Oval shaped horizontally */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  display: flex; /* Added flexbox */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  line-height: 40px; /* same as the height */
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s;
}

.float-button-old:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}


  /* CSS */
  .float-button {
    position: fixed;
    bottom: calc(var(--tabbar-height) + 30px);
    right: 50px;        /* 20 pixels from the right side */
    width: 140px;    /* Width of the button */
    height: 60px;   /* Height of the button */

    align-items: center;
    background-color: initial;
    background-image: linear-gradient(rgba(46, 58, 89, .84), rgba(57, 31, 91, .84) 50%);
    border-radius: 42px;
    border-width: 0;
    box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px,rgba(46, 58, 89, 0.4) 0 8px 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    font-family: Quicksand,sans-serif;
    font-size: 18px;
    font-weight: 700;
    justify-content: center;
    letter-spacing: .04em;
    line-height: 16px;
    margin: 0;
    padding: 18px 18px;
    text-align: center;
    text-decoration: none;
    text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,rgba(255, 255, 255, 0.2) 0 0 12px,rgba(57, 31, 91, 0.6) 1px 1px 4px,rgba(57, 31, 91, 0.32) 4px 4px 16px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
  }
  
  .float-button:hover {
    background-image: linear-gradient(#B384C9, #391F5B 50%);
  }
  
  @media (min-width: 768px) {
    .float-button {
      font-size: 14px;
      padding: 18px 34px;
    }
  }
.actions {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}


/* 기본적으로 5개의 아이템을 한 줄에 표시 */
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px; /* 아이템 간의 간격 */
}

.custom-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: white;
  outline: none;
  width: 100%;
  font-size: 18px;
  cursor: pointer;
}
/* 화면 크기가 1200px 미만일 때 4개의 아이템을 한 줄에 표시 */
@media (max-width: 1800px) {
  .grid-container {
      grid-template-columns: repeat(4, 1fr);
  }
}

/* 화면 크기가 900px 미만일 때 3개의 아이템을 한 줄에 표시 */
@media (max-width: 1500px) {
  .grid-container {
      grid-template-columns: repeat(3, 1fr);
  }
}

/* 화면 크기가 600px 미만일 때 2개의 아이템을 한 줄에 표시 */
@media (max-width: 1200px) {
  .grid-container {
      grid-template-columns: repeat(2, 1fr);
  }
}

/* 화면 크기가 400px 미만일 때 1개의 아이템만 한 줄에 표시 */
@media (max-width: 800px) {
  .grid-container {
      grid-template-columns: repeat(1, 1fr);
  }
}


/* Responsive design for tablets and mobile */
@media (max-width: 800px) {
  .container {
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .container, .list-container {
    padding: 5px;
  }

  
  .float-button {
    position: fixed;
    font-size: 14px;
    bottom: calc(var(--tabbar-height) + 10px);    /* Adjusted to 200 pixels from the bottom */
    right: 20px;        /* 20 pixels from the right side */
    width: 80px;    /* Width of the button */
    height: 50px;
  }

  .product {
    flex: 1 1 100%; /* 모바일에서는 상품 하나만 한 줄에 */
  }

  .thumbnail {
    width: 30%;  /* 이미지의 넓이를 조절합니다 */
    height: 60px; /* 이미지의 높이를 줄임 */
    margin-right: 15px;  /* 이미지와 내용 사이 간격 설정 */
    margin-bottom: 0;
  }

  .detail-pic {
    height: auto;  /* Adjust image height automatically */
  }
}
