:root {
  --header-height: 10vh;
  --navbar-height: 100px;
  --tabbar-height: 100px;
  --text-input-height: 50px;
  --font-large: 1.5rem;
  --font-small: 1.2rem;
  --primary-bg-color: #2E3A59;
  --primary-border-color: #2E3A59;
  --secondary-bg-color: #EFEFF1;
  --box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
}