
.mini-navbar {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke !important;
    color: #2E3A59;
    padding: 0px 10px;         
    display: flex;             
    justify-content: space-between; 
    align-items: center;      
    position: relative; 
    top: 5px;          
    right: 0px;        
    z-index: 1001;     
    font-size: 0.5rem;
    height: 10px;
}

.mini-navbar a {
    color: #2E3A59; 
    margin: 0 10px; 
    text-decoration: none; 
}

.Mui-selected {
    text-decoration: underline;
    
}

.active {
    border-bottom: 2px solid #2E3A59; /* Add underline with desired color */
    background-color: white !important;
} 

.navbar {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
    position: relative;
    width: 100%;
    z-index: 1000;
    background-color: whitesmoke !important;
    color: #2E3A59;
    transform-origin: top; 
}

.navbar-collapse {
    background-color: whitesmoke !important;
}

.navbar-nav .nav-item .dropdown-menu {
    background-color: whitesmoke;
}

.navbar-nav .nav-item .dropdown-menu .dropdown-item {
    color: #2E3A59;
}

.navbar-item.active-link > .nav-link {
    text-decoration: underline;
}
  
.nav-link.active-link {
    text-decoration: underline;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    overflow-y: auto;
    max-height: 300px;
}
  
.navbar-item:hover .dropdown-content {
    display: block;
}
  
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
  
.dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #333;
}
  
.navbar-item .dropdown-content .nav-link.active-link {
    text-decoration: underline;
}

.navbar-nav .nav-item .dropdown-menu .dropdown-item:hover {
    background-color: whitesmoke;
    color: #2E3A59;
}

.navbar img {
    height: 50px;
    width: 50px;
    border-radius: 5%;
    object-fit: cover;
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    color: #2E3A59;
}

.nav-item .nav-link {
    padding: 0.5rem 1rem;
    text-decoration: none;
}

.nav-item .active-link {
    text-decoration: underline;
}

.nav-link.active {
    border-bottom: 2px solid #2E3A59;
}

.nav-centered {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.left-nav,
.right-nav {
    flex: 0.2;
}

.search-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f2f5;
    border: 1px solid #ccd0d5;
    border-radius: 18px;
    padding: 5px 10px;
    max-width: 700px;
    max-height: 44px;
    flex: 0.6;
    margin: auto;
}

.hide {
    transform: translateY(-100%);
    transition: transform 0.3s ease;
}

.category-menu-button {
    width: 70px;
    height: 70px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; 
}

.category-menu-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.search-input {
    border: none;
    padding: 1px;
    border-radius: 18px;
    outline: none;
    background-color: transparent;
    font-size: 16px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: auto 0;
}

.search-btn,
.mic-button {
    background-color: transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    margin: auto 0;
}

.search-btn:hover,
.mic-button:hover {}

.search-btn img,
.mic-button img {
    width: 20px;
    height: 20px;
}

.mic-button:hover {
    background-color: #e0e0e0;
}

.mic-button img {
    width: 24px;
    height: 24px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.category-list {
    display: none; 
    position: absolute;
    z-index: 100;
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    padding: 12px 16px;
    border-radius: 4px;
    left: 0;
    top: 100%;
}
  
.navbar-brand:hover + .category-list,
.category-list:hover {
    display: block;
}

.close-button {
    position: absolute;
    color: black;
    top: 1px;
    right: 1px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar-brand {
        font-size: 1.2rem;
    }

    .nav-item .nav-link {
        padding: 0.7rem 1.2rem;
    }
}
