.container {
    margin-top: calc(var(--navbar-height) + 10px);/*Navbar의 높이*/
    margin-bottom: calc(var(--tabbar-height) + 10px);  /* tabbar의 높이 */
    padding: 15px;
    max-width: 80%;
    background-color: #f8f8f8;
    border-radius: 8px 8px 8px 8px;
  
  }
  .image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}  

.image-viewer .visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out;
}

.image-viewer .hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
}

/* 이미지와 비디오를 뷰어 크기에 맞게 확장 */
.image-viewer img, .image-viewer video {
    max-width: 100%;  /* 뷰포트의 너비에 따라 최대 80%까지 확장 */
    max-height: 100%; /* 뷰포트의 높이에 따라 최대 80%까지 확장 */
    object-fit: contain;  /* 이미지나 비디오의 비율을 유지하면서 가능한 많이 확장 */
}

.image-viewer-header {
    position: fixed; /* 위치 고정 */
    top: 0; /* 화면의 상단에 위치 */
    left: 0; /* 화면의 좌측에 위치 */
    width: 100%; /* 화면의 전체 너비를 차지 */
    z-index: 10; /* 다른 요소 위에 위치하도록 z-index 설정 */
    background-color: rgba(0,0,0,0.7); /* 헤더의 배경색 */
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    margin-top: 0px;
}

.image-viewer-footer {
    position: fixed; 
    bottom: 0; 
    left: 50%; 
    width: 100%; 
    z-index: 10; 
    background-color: rgba(0,0,0,0.7); 
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    transform: translateX(-50%);
}


.image-viewer-header span,
.image-viewer-header small {
    color: inherit; /* 상위 요소의 색상을 상속받도록 설정 */
}

.image-viewer-footer {
    margin-bottom: calc(var(--tabbar-height) + 10px);
    display: flex;
    justify-content: center;
    align-items: center;
}

button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 38px;
    margin: 5px;
    padding: 10px 25px;
    cursor: pointer;
    color: #eee; /* 밝은 글자 색으로 변경 */
    transition: 0.3s; /* 버튼 호버 효과를 위한 전환 효과 추가 */
    /* transform: translateY(-50%); */
    /* position: absolute; */
    
    
}

button:hover {
    color: #ccc; /* 호버 상태에서의 색상을 약간 어둡게 변경 */
}



.header-center {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 15px;
}

.image-viewer-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.image-viewer-content img,
.image-viewer-content video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
    margin-right: 8px;
    margin-bottom: 8px;
    margin: auto;
    transition: transform 0.3s;
} 
/* .image-viewer-content img:hover,
.image-viewer-content video:hover {
    transform: scale(1.1);
} */
.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-container video {
    max-width: 80%;
    max-height: 80%;
}

/* 좌우이동버튼관련 */
.navigate-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 15px;
    font-size: 4rem;
    cursor: pointer;
    z-index: 10; /* 버튼이 이미지 위에 표시되도록 */
    border: none;
    outline: none;
    border-radius: 4px;
}


.navigate-button.prev {
    left: 10px;
}

.navigate-button.next {
    right: 10px;
}

@media (max-width: 768px) {
    .navigate-button {

        padding: 5px 10px;
        font-size: 2rem;
    }

    .dots {
      bottom: 5px;
    }
  
    .dot {
      width: 6px;
      height: 6px;
    }
  }
  