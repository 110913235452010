.search-list-container {
    padding: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .search-section {
    margin-bottom: 20px;
  }
  
  .search-section h4 {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .search-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .search-section ul li {
    padding: 8px;
    cursor: pointer;
    color: #007BFF;
  }
  
  .search-section ul li:hover {
    background-color: #f1f1f1;
  }
  
  .search-section p {
    color: #999;
  }
  
  .search-section button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-section button:hover {
    background-color: #0056b3;
  }
  