.slider {
  display: flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.image-slider {
  display: flex;
  transition: transform 1.5s;
  position: relative;
  align-items: center;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.slider-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.slider-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: 'inline-block';
}

.image-slider:hover .slider-image {
  /* transform: scale(1.2); */
  transition: transform 1.5s ease-in-out;
}

.dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.dot.active {
  background-color: #717171;
}

@media (max-width: 768px) {
  .slider-button {
    padding: 7px;
    font-size: 2em;
  }

  .dots {
    bottom: 5px;
  }

  .dot {
    width: 6px;
    height: 6px;
  }
}
