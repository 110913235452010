.signup-flow {
    position: relative;
    height: 100%;
    overflow-y: auto;
}

 .signup-flow .container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 60px); 
    padding: 10px; 
    margin : 10px;
} 

.signup-flow .title {
    color: #333;
    font-size: 24px;
    margin-bottom: 5px;  /* Reduced margin */
}

.signup-flow .text {
    color: #666;
    font-size: 16px;
    margin-bottom: 10px;  /* Reduced margin */
    text-align: center;
}

.signup-flow .step-content {
    flex: 1;
    overflow-y: auto;
    /* padding: 10px 10px;   */
    display: flex;
     /*justify-content: center;
    align-items: center; */
    /* justify-content: space-between; */
    /*height: 100vh;  viewport height */
    flex-direction: column; /* child elements will stack vertically */
}
/* 각 항목의 간격 조절 */
.signup-flow .form-section {
    margin-bottom: 5px;
}
/* .signup-flow .region-container {
    position: relative;
    flex: 1; 
    margin:5px;
} */

.signup-flow .fixed-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

/* Region 컴포넌트와 관련된 CSS */
/* .signup-flow .region-wrapper {
    position: relative;
    height: 100%;
    flex: 1; 
} */

.signup-flow .map-container {
    width: 100vw;   
    height: 100vh;  
    position: relative; 
}

/* Region에 해당하는 영역의 높이를 원하는 비율이나 퍼센트로 설정하세요. */
.signup-flow .region-content {
    height: 80%;
}

.signup-flow .step-content,
.signup-flow .region-container,
.signup-flow .form-section {
    height: 100%;
}

.signup-flow .done-button {
    position: fixed;
    bottom: 15px;
    left: 30px;
    display: flex;
    gap: 10px;
}

.signup-flow .close-button {
    position: absolute;
    color: black;
    top: 1px;
    right: 1px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

.signup-flow .navigation-buttons {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
}
/* 
.signup-flow .container .navigation-buttons .button {
    padding: 6px 12px;
    font-size: 10px;  
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    border-radius: 3px; 
    cursor: pointer;
    transition: background-color 0.2s;
} */

.signup-flow .button {
    padding: 6px 12px;  /* Reduced padding further */
    font-size: 9px;    /* Reduced font size */
    background-color: #007BFF;
    color: #ffffff;
    border: none;
    border-radius: 3px; /* Reduced border-radius for smaller button aesthetic */
    cursor: pointer;
    transition: background-color 0.2s;
}
.signup-flow .button:hover {
    background-color: #0056b3;
}

.signup-flow .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.signup-flow .Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;  
    z-index: 1000;
    width: 100%;
    max-width: 1200px;
}
.signup-flow .profile-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 5px;
    margin-top: 10px;
    margin-bottom: calc(var(--tabbar-height) + 10px);  /* tabbar의 높이 */
    margin-left:auto;
    margin-right:auto;
}

.signup-flow .input-group {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.signup-flow .input-group label {
    font-weight: bold;
}

.signup-flow .prevnext-button {
    background-color: #4CAF50; /* 적절한 색상으로 변경 가능 */
    color: white;
    font-size: 14px;
    padding: 10px 55px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.signup-flow .prevnext-button:hover {
    background-color: #45a049;
}

/*파일 업로드*/
.signup-flow .progress-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
    border-radius: 10px;
  }
  
  .signup-flow .circular-progress {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .signup-flow .circle-bg,
  .signup-flow .circle {
    fill: none;
    stroke-width: 6;
    cx: 50%;
    cy: 50%;
    r: 44; /* r=45에서 선의 두께 절반인 3을 빼서 42로 설정 */
  }
  
  .signup-flow .circle-bg {
    stroke: #eee;
  }
  
  .signup-flow .circle {
    stroke: #f88;  /* 변경된 색상 */
    stroke-linecap: round;
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
  
  .signup-flow .progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: bold;
  }

  .spinner {
    border: 8px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 8px solid #000;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
@media (max-width: 768px) {
    .signup-flow .container {
        padding: 10px;
        max-width: 100%;
    }
    .signup-flow .button {
        font-size: 9px;
    }

    .signup-flow .Modal {
        width: 90%; 
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .signup-flow .container {
        padding: 15px;
        max-width: 100%;
    }
    .signup-flow .button {
        font-size: 12px;
    }
}

@media (min-width: 1024px) {
    .signup-flow .container {
        padding: 30px;
        max-width: 100%;
    }
    .signup-flow .button {
        font-size: 16px;
    }
}
