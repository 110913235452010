 * {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   font-family: 'Arial', sans-serif;
   /* You can change this to another font if preferred */
 }
 .container {
  margin-top: calc(var(--navbar-height) + 10px);/*Navbar의 높이*/
  margin-bottom: calc(var(--tabbar-height) + var(--text-input-height) + 10px);  /*tabbar의 높이 */
  margin-left:auto;
  margin-right:auto;
  padding: 15px;
  max-width: 80%;  /* 최대 너비를 90%로 설정. 원하는 비율로 조절 가능. */
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
   border-radius: 8px 8px 8px 8px;
}

 .order-card {
   border: 1px solid #ccc;
   padding: 16px;
   margin: 16px 0;
   display: flex;
   flex-direction: column;
   align-items: center;
 }
/* 
 .product-image {
   width: 150px;
   margin-bottom: 16px;
 } */

 .orders-table { 
   width: 100%; 
   border-collapse: collapse; 
   margin-top: 20px; 
 }
 .orders-table th, .orders-table td { 
   border: 1px solid #ccc; 
   padding: 8px; 
   text-align: center; 
 }
 .product-image { 
   width: 50px; 
 }