  .review-list {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
  }
  .review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .review-item {
    margin-bottom: 20px;
  }
  
  .review-link {
    text-decoration: none;
    color: inherit;
  }
  
  .review-rating, .review-text {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .image-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  
  .review-image {
    max-width: 100px; /* Adjust based on your layout */
    height: auto;
    border-radius: 5px;
  }
  