.join .container {
    background-color: #fff;
    border-radius: 10px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
        0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }


.join .registration-card {
    padding: 40px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12); /* Darker shadow for depth perception */
    border-radius: 15px;
    width: 100%;
    max-width: 768px; /* Decreased the maximum width */
    text-align: center; /* Center the text elements */
}
.join form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }
  


  
  .join .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }
  
  .join .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
  }
  
  .join .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }
  
  .join .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }
  
  .join .container.right-panel-active .join .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }
.join .textfield {
    margin: 15px 0; /* More margin for spacing */
}

.join .button {
    margin-top: 25px;
    width: 100%;
    padding: 12px 0;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.join .button:hover {
    background-color: #0056b3;
    transform: scale(1.05); /* Slight scaling effect on hover */
}

.join .toast {
    margin-top: 30px;
    color: red;
}

@media (max-width: 768px) {
    .join .container {
        padding: 20px;
    }

    .join .registration-card {
        padding: 20px;
    }

    .join .textfield {
        margin: 12px 0;
    }

    .join .button {
        padding: 14px 0;
        font-size: 1.1em;
    }
}
