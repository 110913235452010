.product-categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* 항목 간 간격 */
  }
  

  .category-item {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .category-item:hover {
    background-color: #f0f0f0;
  }
  
  .category-item:hover {
    background-color: #f0f0f0;
  }
  .categories-layout {
    display: flex;
  }
  
  .category-list {
    flex: 1; /* 왼쪽 카테고리 목록 영역 */
    max-width: 200px; /* 최대 너비 */
  }
  
  .category-details {
    flex: 3; /* 오른쪽 상세 정보 영역 */
  }
  .button {
    background-color: white; /* White background */
    color: black; /* Text color */
    border: 1px solid gray; /* Border color and size */
    padding: 10px 20px; /* Vertical and horizontal padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 8px; /* Text size */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px; /* Rounded corners */
  }
  
  .siblingCategoryList {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .siblingCategoryItem {
    padding: 5px;
    cursor: pointer;
  }
  
  .siblingCategoryItem:hover {
    background-color: #f0f0f0;
  }