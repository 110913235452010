/* ProductReviewCommentForm.css */
.form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
  }
  
  .textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical; /* 사용자가 세로 크기 조정 가능 */
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #007bff;
    font-size: 16px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  