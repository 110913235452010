.left-menu-container {
    margin-top: calc(var(--navbar-height) + 10px);
    width: 250px;
    background-color: #f9f9f9;
    height: 100vh;
    border-right: 1px solid #e2e2e2;
}

.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.menu-item:hover {
    background-color: #e2e2e2;
}

.menu-icon {
    margin-right: 10px;
}

.menu-divider {
    margin: 0;
    opacity: 0.5;
}

.submenu-list {
    padding: 0;
    margin: 0;
    margin-left: 20px;
}

.submenu-item {
    padding: 5px 20px;
}
